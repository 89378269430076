import { AuctionInfoDto } from '@/features/auction/auctionInfo.dto';
import { useMemo } from 'react';

export const AuctionState = ({ auctionInfo }: { auctionInfo: AuctionInfoDto }) => {
  const startDate = auctionInfo.startAt.getTime();
  const endDate = auctionInfo.endAt.getTime();
  const isWaitingForStart = useMemo(() => Date.now() < startDate, [startDate]);
  const isAuctionFinished = useMemo(() => Date.now() >= endDate, [endDate]);
  const isAuctionInProcess = !isWaitingForStart && !isAuctionFinished;
  return (
    <span className="font-bold text-gray-8a">
      {isAuctionInProcess ? 'Happening now' : isWaitingForStart ? 'Waiting' : 'Finished'}
    </span>
  );
};
