import React from 'react';

export function ContactInfoVerifiedBadge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="36"
      viewBox="0 0 32 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9776 32.5331L14.902 31.9982C11.6305 30.3935 7.28339 27.3624 4.9978 22.1026C2.3985 16.085 3.60852 9.13125 3.65334 8.8638L4.32557 5.16408C6.47671 6.50133 9.83788 10.6468 15.9776 4.85205C21.221 9.7553 23.6411 7.74943 27.6745 5.34238L28.3467 8.8638C28.3915 9.17583 29.6015 16.085 27.0022 22.1472C24.7166 27.407 20.3695 30.3935 17.098 32.0428L15.9776 32.5331Z"
        fill="#CBB133"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8965 35.8434L13.5732 34.688C9.96379 32.9161 4.92376 29.4598 2.24634 23.2983L2.24374 23.2923C0.724344 19.7747 0.352338 16.0872 0.334092 13.3469C0.324847 11.9584 0.406359 10.767 0.494157 9.8976C0.53816 9.46186 0.583998 9.10452 0.621414 8.84098C0.640139 8.70909 0.656725 8.60092 0.669862 8.51847C0.676432 8.47723 0.682063 8.44291 0.686549 8.41594C0.689858 8.39606 0.69548 8.36275 0.694594 8.36803L0.697986 8.34779L2.1626 0.287109L5.90942 2.61631C6.29957 2.85884 6.68335 3.14309 6.9805 3.36373C7.01018 3.38577 7.03943 3.40751 7.06831 3.42897C7.35892 3.64491 7.61124 3.83241 7.87946 4.01515C8.46366 4.41317 8.94426 4.66001 9.39807 4.77588C9.99067 4.9272 11.3514 5.09326 13.9185 2.67038L15.9681 0.7359L18.0267 2.6609C20.3736 4.85563 21.4569 4.90974 21.9596 4.85578C22.325 4.81655 22.795 4.67048 23.512 4.30223C24.0675 4.01691 24.6152 3.68522 25.302 3.26931C25.5585 3.11403 25.8343 2.94702 26.1371 2.7663L29.8176 0.569862L31.3064 8.36908L31.3162 8.43732C31.2908 8.26003 31.3638 8.74782 31.3794 8.85948C31.4164 9.12342 31.462 9.48147 31.506 9.91816C31.5936 10.7894 31.6752 11.9805 31.6659 13.3692C31.6477 16.1118 31.275 19.7948 29.7595 23.3294L29.7566 23.3361L29.7537 23.3428C27.0697 29.5196 22.0138 32.9244 18.4485 34.7217L18.3756 34.7585L15.8965 35.8434ZM15.9776 32.5332L14.902 31.9983C11.6305 30.3936 7.28339 27.3625 4.9978 22.1026C2.42389 16.1438 3.58524 9.26708 3.65169 8.87361C3.65235 8.86972 3.6529 8.86647 3.65334 8.86386L4.32557 5.16413C4.62066 5.34757 4.93851 5.58386 5.28319 5.84008C5.80241 6.22605 6.38249 6.65726 7.03726 7.02121C9.11645 8.17693 11.9488 8.65451 15.9776 4.85211C19.9322 8.55015 22.2808 8.31814 24.9268 6.94802C25.5693 6.61531 26.2294 6.21547 26.9343 5.78851C27.1754 5.64246 27.4218 5.49323 27.6745 5.34243L28.3467 8.86386C28.3482 8.87432 28.351 8.89217 28.3549 8.91716C28.4683 9.63788 29.5144 16.2881 27.0022 22.1472C24.7166 27.4071 20.3695 30.3936 17.098 32.0429L15.9776 32.5332Z"
        fill="white"
      />
      <path
        d="M15.9802 9.28101C15.9802 9.28101 16.8389 10.262 20.138 10.7079C23.437 11.1538 25.29 9.94986 25.29 9.94986C25.29 9.94986 26.3746 15.8804 24.1601 20.9637C22.1716 25.4673 18.1947 27.9198 15.9802 28.9899C13.7658 27.9198 9.78877 25.4673 7.80028 20.9637C5.63102 15.925 6.71565 9.94986 6.71565 9.94986C6.71565 9.94986 8.56856 11.1092 11.8676 10.7079C15.1215 10.3066 15.9802 9.28101 15.9802 9.28101Z"
        fill="#012138"
      />
      <path
        d="M14.9194 23.0108L10.4445 18.5432L12.2344 16.7561L14.9194 19.3942L21.099 13.2671L22.8889 15.0542L14.9194 23.0108Z"
        fill="white"
      />
    </svg>
  );
}

export function IsNotVerifiedBadge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="36"
      viewBox="0 0 32 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9776 32.5331L14.902 31.9982C11.6305 30.3935 7.28339 27.3624 4.9978 22.1026C2.3985 16.085 3.60852 9.13125 3.65334 8.8638L4.32557 5.16408C6.47671 6.50133 9.83788 10.6468 15.9776 4.85205C21.221 9.7553 23.6411 7.74943 27.6745 5.34238L28.3467 8.8638C28.3915 9.17583 29.6015 16.085 27.0022 22.1472C24.7166 27.407 20.3695 30.3935 17.098 32.0428L15.9776 32.5331Z"
        fill="#CBB133"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8965 35.8434L13.5732 34.688C9.96379 32.9161 4.92376 29.4598 2.24634 23.2983L2.24374 23.2923C0.724344 19.7747 0.352338 16.0872 0.334092 13.3469C0.324847 11.9584 0.406359 10.767 0.494157 9.8976C0.53816 9.46186 0.583998 9.10452 0.621414 8.84098C0.640139 8.70909 0.656725 8.60092 0.669862 8.51847C0.676432 8.47723 0.682063 8.44291 0.686549 8.41594C0.689858 8.39606 0.69548 8.36275 0.694594 8.36803L0.697986 8.34779L2.1626 0.287109L5.90942 2.61631C6.29957 2.85884 6.68335 3.14309 6.9805 3.36373C7.01018 3.38577 7.03943 3.40751 7.06831 3.42897C7.35892 3.64491 7.61124 3.83241 7.87946 4.01515C8.46366 4.41317 8.94426 4.66001 9.39807 4.77588C9.99067 4.9272 11.3514 5.09326 13.9185 2.67038L15.9681 0.7359L18.0267 2.6609C20.3736 4.85563 21.4569 4.90974 21.9596 4.85578C22.325 4.81655 22.795 4.67048 23.512 4.30223C24.0675 4.01691 24.6152 3.68522 25.302 3.26931C25.5585 3.11403 25.8343 2.94702 26.1371 2.7663L29.8176 0.569862L31.3064 8.36908L31.3162 8.43732C31.2908 8.26003 31.3638 8.74782 31.3794 8.85948C31.4164 9.12342 31.462 9.48147 31.506 9.91816C31.5936 10.7894 31.6752 11.9805 31.6659 13.3692C31.6477 16.1118 31.275 19.7948 29.7595 23.3294L29.7566 23.3361L29.7537 23.3428C27.0697 29.5196 22.0138 32.9244 18.4485 34.7217L18.3756 34.7585L15.8965 35.8434ZM15.9776 32.5332L14.902 31.9983C11.6305 30.3936 7.28339 27.3625 4.9978 22.1026C2.42389 16.1438 3.58524 9.26708 3.65169 8.87361C3.65235 8.86972 3.6529 8.86647 3.65334 8.86386L4.32557 5.16413C4.62066 5.34757 4.93851 5.58386 5.28319 5.84008C5.80241 6.22605 6.38249 6.65726 7.03726 7.02121C9.11645 8.17693 11.9488 8.65451 15.9776 4.85211C19.9322 8.55015 22.2808 8.31814 24.9268 6.94802C25.5693 6.61531 26.2294 6.21547 26.9343 5.78851C27.1754 5.64246 27.4218 5.49323 27.6745 5.34243L28.3467 8.86386C28.3482 8.87432 28.351 8.89217 28.3549 8.91716C28.4683 9.63788 29.5144 16.2881 27.0022 22.1472C24.7166 27.4071 20.3695 30.3936 17.098 32.0429L15.9776 32.5332Z"
        fill="white"
      />
      <path
        d="M15.9802 9.28101C15.9802 9.28101 16.8389 10.262 20.138 10.7079C23.437 11.1538 25.29 9.94986 25.29 9.94986C25.29 9.94986 26.3746 15.8804 24.1601 20.9637C22.1716 25.4673 18.1947 27.9198 15.9802 28.9899C13.7658 27.9198 9.78877 25.4673 7.80028 20.9637C5.63102 15.925 6.71565 9.94986 6.71565 9.94986C6.71565 9.94986 8.56856 11.1092 11.8676 10.7079C15.1215 10.3066 15.9802 9.28101 15.9802 9.28101Z"
        fill="#FD394B"
      />
      <path
        d="M16 17.59L19.59 14L21 15.41L17.41 19L21 22.59L19.59 24L16 20.41L12.41 24L11 22.59L14.59 19L11 15.41L12.41 14L16 17.59Z"
        fill="white"
      />
    </svg>
  );
}

export function IsIdentityVerifiedBadge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="32"
      height="36"
      viewBox="0 0 32 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9776 32.5331L14.902 31.9982C11.6305 30.3935 7.28339 27.3624 4.9978 22.1026C2.3985 16.085 3.60852 9.13125 3.65334 8.8638L4.32557 5.16408C6.47671 6.50133 9.83788 10.6468 15.9776 4.85205C21.221 9.7553 23.6411 7.74943 27.6745 5.34238L28.3467 8.8638C28.3915 9.17583 29.6015 16.085 27.0022 22.1472C24.7166 27.407 20.3695 30.3935 17.098 32.0428L15.9776 32.5331Z"
        fill="#CBB133"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8965 35.8434L13.5732 34.688C9.96379 32.9161 4.92376 29.4598 2.24634 23.2983L2.24374 23.2923C0.724344 19.7747 0.352338 16.0872 0.334092 13.3469C0.324847 11.9584 0.406359 10.767 0.494157 9.8976C0.53816 9.46186 0.583998 9.10452 0.621414 8.84098C0.640139 8.70909 0.656725 8.60092 0.669862 8.51847C0.676432 8.47723 0.682063 8.44291 0.686549 8.41594C0.689858 8.39606 0.69548 8.36275 0.694594 8.36803L0.697986 8.34779L2.1626 0.287109L5.90942 2.61631C6.29957 2.85884 6.68335 3.14309 6.9805 3.36373C7.01018 3.38577 7.03943 3.40751 7.06831 3.42897C7.35892 3.64491 7.61124 3.83241 7.87946 4.01515C8.46366 4.41317 8.94426 4.66001 9.39807 4.77588C9.99067 4.9272 11.3514 5.09326 13.9185 2.67038L15.9681 0.7359L18.0267 2.6609C20.3736 4.85563 21.4569 4.90974 21.9596 4.85578C22.325 4.81655 22.795 4.67048 23.512 4.30223C24.0675 4.01691 24.6152 3.68522 25.302 3.26931C25.5585 3.11403 25.8343 2.94702 26.1371 2.7663L29.8176 0.569862L31.3064 8.36908L31.3162 8.43732C31.2908 8.26003 31.3638 8.74782 31.3794 8.85948C31.4164 9.12342 31.462 9.48147 31.506 9.91816C31.5936 10.7894 31.6752 11.9805 31.6659 13.3692C31.6477 16.1118 31.275 19.7948 29.7595 23.3294L29.7566 23.3361L29.7537 23.3428C27.0697 29.5196 22.0138 32.9244 18.4485 34.7217L18.3756 34.7585L15.8965 35.8434ZM15.9776 32.5332L14.902 31.9983C11.6305 30.3936 7.28339 27.3625 4.9978 22.1026C2.42389 16.1438 3.58524 9.26708 3.65169 8.87361C3.65235 8.86972 3.6529 8.86647 3.65334 8.86386L4.32557 5.16413C4.62066 5.34757 4.93851 5.58386 5.28319 5.84008C5.80241 6.22605 6.38249 6.65726 7.03726 7.02121C9.11645 8.17693 11.9488 8.65451 15.9776 4.85211C19.9322 8.55015 22.2808 8.31814 24.9268 6.94802C25.5693 6.61531 26.2294 6.21547 26.9343 5.78851C27.1754 5.64246 27.4218 5.49323 27.6745 5.34243L28.3467 8.86386C28.3482 8.87432 28.351 8.89217 28.3549 8.91716C28.4683 9.63788 29.5144 16.2881 27.0022 22.1472C24.7166 27.4071 20.3695 30.3936 17.098 32.0429L15.9776 32.5332Z"
        fill="white"
      />
      <path
        d="M15.9802 9.28101C15.9802 9.28101 16.8389 10.262 20.138 10.7079C23.437 11.1538 25.29 9.94986 25.29 9.94986C25.29 9.94986 26.3746 15.8804 24.1601 20.9637C22.1716 25.4673 18.1947 27.9198 15.9802 28.9899C13.7658 27.9198 9.78877 25.4673 7.80028 20.9637C5.63102 15.925 6.71565 9.94986 6.71565 9.94986C6.71565 9.94986 8.56856 11.1092 11.8676 10.7079C15.1215 10.3066 15.9802 9.28101 15.9802 9.28101Z"
        fill="#012138"
      />
      <path
        d="M13.542528000516885,21.185084422378406 L10.681726213913503,18.292534126576072 L11.826008570578768,17.135475161219205 L13.542528000516885,18.843514586269826 L17.493143817740574,14.876520021534738 L18.63742617440583,16.033578986891605 L13.542528000516885,21.185084422378406 z"
        fill="white"
      />
      <path
        d="M16.96603402201652,21.222296473829612 L14.105232235413137,18.329746178027275 L15.249514592078402,17.17268721267041 L16.96603402201652,18.880726637721033 L20.916649839240208,14.913732072985944 L22.06093219590547,16.07079103834281 L16.96603402201652,21.222296473829612 z"
        fill="white"
      />
      <path
        d="M34.547252585036745,41.96653687700497 L31.686450798433366,39.07398658120263 L32.83073315509863,37.916927615845765 L34.547252585036745,39.62496704089639 L38.49786840226044,35.6579724761613 L39.642150758925695,36.815031441518165 L34.547252585036745,41.96653687700497 z"
        fill="white"
      />
    </svg>
  );
}

export function IsVIPBadge({ className = 'w-6' }: { className?: string }) {
  return <img src={'/images/star.png'} alt="logo" className={className} />;
}
