import UserVerificationLevelBadge from '@/components/@next/UserVerificationLevelBadge/UserVerificationLevelBadge';
import routes from '@/routes/routes';
import { UserDto } from '@/types';
import cn from 'classnames';
import Link from 'next/link';

const UsernameLink = ({
  user,
  textWhite,
  wrapperClass,
}: {
  user: Pick<UserDto, 'id' | 'userName' | 'verificationLevel'>;
  textWhite?: boolean;
  wrapperClass?: string;
}) => {
  return (
    <div className={cn('flex flex-row items-center gap-x-2 cursor-pointer', wrapperClass)}>
      <Link href={routes.showcase({ userId: user.id })}>
        <span
          className={cn(
            'font-bold text-base',
            textWhite && 'text-white hover:text-link transition',
          )}
        >
          {user.userName}
        </span>
      </Link>
      <UserVerificationLevelBadge user={user} size="30px" />
    </div>
  );
};

export default UsernameLink;
