import { AuctionInfoDto } from '@/features/auction/auctionInfo.dto';
import { formatCents } from '@/utils/formatCents';
import cn from 'classnames';

const AuctionPrice = ({
  auctionInfo,
  isGridStyle = true,
}: {
  auctionInfo: AuctionInfoDto;
  isGridStyle?: boolean;
}) => {
  const { startingPrice, currentPrice } = auctionInfo;
  return (
    <div
      className={cn(
        'flex justify-start',
        isGridStyle
          ? 'flex-col items-start'
          : 'col-span-6 sm:col-span-4 flex flex-col sm:flex-row gap-1 items-start sm:items-center',
      )}
    >
      <span className="text-sm font-bold text-gray-8a">
        {currentPrice ? 'Current High Bid' : 'Starting Price'}
      </span>
      <span className="text-primary text-lg font-bold">
        {currentPrice ? formatCents(currentPrice) : formatCents(startingPrice)}
      </span>
    </div>
  );
};

export default AuctionPrice;
