import { UserDto } from '@/types';
import { Tooltip } from 'antd';
import {
  ContactInfoVerifiedBadge,
  IsIdentityVerifiedBadge,
  IsNotVerifiedBadge,
} from '../icons/SecurityBadge';

const UserVerificationLevelBadge = ({
  user: { verificationLevel },
  size = '25px',
}: {
  user: Pick<UserDto, 'verificationLevel'>;
  size?: string;
}) => {
  switch (verificationLevel) {
    case 'not-verified':
      return (
        <Tooltip
          placement="right"
          title={'Not Verified member!'}
          overlayInnerStyle={{
            padding: '3px 4px',
            fontSize: '10px',
            minHeight: '15px',
          }}
        >
          <IsNotVerifiedBadge height={size} />
        </Tooltip>
      );

    case 'contact-info-verified':
      return (
        <Tooltip
          placement="right"
          title={'Contact Info Verified member!'}
          overlayInnerStyle={{
            padding: '3px 4px',
            fontSize: '10px',
            minHeight: '15px',
          }}
        >
          <ContactInfoVerifiedBadge height={size} />
        </Tooltip>
      );

    case 'identity-verified':
      return (
        <Tooltip
          placement="right"
          title={'Identity Verified!'}
          overlayInnerStyle={{
            padding: '3px 4px',
            fontSize: '10px',
            minHeight: '15px',
          }}
        >
          <IsIdentityVerifiedBadge height={size} />
        </Tooltip>
      );

    default:
      return null;
  }
};

export default UserVerificationLevelBadge;
