import { invalidateShowcaseItemQueries } from '@/api/showcaseItems/showcaseItems.api';
import { AuctionInfoDto } from '@/features/auction/auctionInfo.dto';
import { useState } from 'react';
import Countdown from 'react-countdown';

const AuctionCountDown = ({
  auctionInfo,
  className,
  titleClassName,
  countDownClassName,
  showTitle = true,
}: {
  auctionInfo: AuctionInfoDto;
  className?: string;
  titleClassName?: string;
  countDownClassName?: string;
  showTitle?: boolean;
}) => {
  const startDate = auctionInfo.startAt.getTime();
  const endDate = auctionInfo.endAt.getTime();
  const [isWaitingForStart, setIsWaitingForStart] = useState(Date.now() < startDate);
  const [isAuctionFinished, setIsAuctionFinished] = useState(Date.now() >= endDate);
  const isAuctionFinalizing = isAuctionFinished && auctionInfo.status === 'started';
  const isAuctionInProcess = !isWaitingForStart && !isAuctionFinished;
  const isAuctionStarting = isAuctionInProcess && auctionInfo.status === 'waiting';
  const countDownClass = countDownClassName ?? 'text-primary text-lg font-bold';
  const titleClass = titleClassName ?? 'text-xs font-bold text-gray-8a';
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    if (completed) return null;
    if (days > 0) {
      return (
        <span className={countDownClass}>
          {days}d {hours}h
        </span>
      );
    }
    if (hours > 0) {
      return (
        <span className={countDownClass}>
          {hours}h {minutes.toString().padStart(2, '0')}m
        </span>
      );
    }
    if (minutes > 0) {
      return (
        <span className={countDownClass}>
          {minutes.toString().padStart(2, '0')}m {seconds.toString().padStart(2, '0')}s
        </span>
      );
    }

    return <span className={countDownClass}>{seconds}s</span>;
  };

  return (
    <div className={className ? className : 'flex flex-col justify-start items-start'}>
      {showTitle && (
        <span className={titleClass}>
          {isWaitingForStart ? 'Time to start' : isAuctionInProcess ? 'Time left' : 'Status'}
        </span>
      )}

      {!isAuctionStarting && (isWaitingForStart || isAuctionInProcess) && (
        <Countdown
          date={isWaitingForStart ? startDate : endDate}
          renderer={renderer}
          onComplete={() => {
            isWaitingForStart ? setIsWaitingForStart(false) : setIsAuctionFinished(true);
            setTimeout(() => {
              invalidateShowcaseItemQueries.byId(auctionInfo.showcaseItemId);
            }, 3000);
          }}
        />
      )}
      {isAuctionStarting && <span className={countDownClass}>Starting...</span>}
      {isAuctionFinished && (
        <span className={countDownClass}>{isAuctionFinalizing ? 'Finalizing...' : 'Finished'}</span>
      )}
    </div>
  );
};

export default AuctionCountDown;
